import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import { sendGetRequest } from "../../Common/sendGetRequest";
import FormHelperText from '@mui/material/FormHelperText';

const SurveySelect = (props) => {
  const [options, setOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (SelectChangeEvent) => {
    props.setSurveyId(SelectChangeEvent.target.value);
  };

  useEffect(() => {
    sendGetRequest("getSurveys.php?target="+props.target).then(
      (response) => setOptions(response)
    );
  }, [props.target]);

  const menuItems = options.map((option) => (
    <MenuItem key={option.ID} value={option.ID}>
      {option.Survey}
    </MenuItem>
  ));

  useEffect(() => {  
    menuItems.some(item => item.key === props.surveyId) && props.setSurveyId("");
  }, [props.target]);

  return (
    <FormControl
      variant="standard"
      style={{ width:props.width }}
      error={props.error}
    >
      <InputLabel id="SurveyAuswahl-label">Fragebogen für {props.target}</InputLabel>
      <Select
        labelId="SurveyAuswahl-label"
        id="SurveyAuswahl"
        value={props.surveyId}
        onChange={handleChange}
        label={"Fragebogen für "+ props.target}
      >
      {menuItems}

      </Select>
      <FormHelperText id="outlined-weight-helper-text"> {props.error ? 'Pflichtfeld bitte befüllen' : ''}</FormHelperText>
    </FormControl>
  );
};

export default SurveySelect;
