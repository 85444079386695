import ReactApexChart from "react-apexcharts";
import { TimeLineDiagram } from "../../../Diagrams/TimeLineDiagram";

export default function BewertungBarDiagram(props) {
  const average = props.average ? 25 * props.average - 25 : 190; //average color is calculated as gradient between red and green
  const averageColor = "hsl(" + average + ", 90%, 45%)";

  const evaluationTimelineDiagram = JSON.parse(JSON.stringify(TimeLineDiagram));

    evaluationTimelineDiagram.options.yaxis = [
      {
        min: 1,
        max: props.max,
        title: {
          text: "Bewertung",
        },
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return value.toFixed(1);
          },
        },
      },
      {
        min: 0,
        max:
          props.numberForTimelineChart.reduce(
            (max, obj) => (obj.y > max ? obj.y : max),
            -Infinity
          ) +
          Math.ceil(
            props.numberForTimelineChart.reduce(
              (max, obj) => (obj.y > max ? obj.y : max),
              -Infinity
            ) * 0.1
          ),
        opposite: true,
        title: {
          text: "Anzahl abgegebene Bewertungen",
        },
        forceNiceScale: true,
        labels: {
          formatter: function (value) {
            return value.toFixed(1);
          },
        },
      },
    ];

  evaluationTimelineDiagram.options.responsive = [
    {
      breakpoint: 767,
      options: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
      },
    },
  ];

  evaluationTimelineDiagram.series = [];

    evaluationTimelineDiagram.series = [
      {
        name: "Durchschnittliche Bewertung",
        data: props.seriesForTimelineChart,
      },
      {
        name: "Abgegebene Bewertungen",
        data: props.numberForTimelineChart,
      },
    ];


  evaluationTimelineDiagram.options.title.text = "Timeline";

  return (
    <ReactApexChart
      options={evaluationTimelineDiagram.options}
      type={props.type}
      series={evaluationTimelineDiagram.series}
      height={360}
    />
  );
}
