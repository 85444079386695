import * as React from "react";
import Chip from "@mui/material/Chip";
import { getQuestionDescription } from "../Evaluation/evaluationFunctions";
import { updateState } from "../../Surveys/surveysFunctions";
import { sendGetRequest } from "../../../Common/sendGetRequest";
import UserAvatar from "../../Users/UserAvatar";
import LocalLibraryTwoToneIcon from "@mui/icons-material/LocalLibraryTwoTone";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import { useLocation, useNavigate } from "react-router-dom";

export default function FilterChips(props) {
  const [chipData, setChipData] = React.useState([
    { key: 5, label: "" },
    { key: 4, label: "" },
    { key: 0, label: "" },
    { key: 1, label: "" },
    { key: 2, label: "" },
  ]);

  const [users, setUsers] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryTrainer = queryParams.get("trainer");
  const querySurvey = queryParams.get("survey");
  const queryCourse = queryParams.get("course");
  const queryCategory = queryParams.get("category");
  const [name, setName] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [userAvatar, setUserAvatar] = React.useState("");
  const [courses, setCourses] = React.useState([]);
  const [surveys, setSurveys] = React.useState([]);

  React.useEffect(() => {
    sendGetRequest("getTrainers.php").then((response) => {
      setUsers(response)
      if (queryTrainer !== null) {
        let desiredUser = response.find(
          (user) => user.ID === queryTrainer
        );
        updateChipState(desiredUser.Vorname + " " + desiredUser.Nachname, 2);
        setName(desiredUser.Vorname + " " + desiredUser.Nachname, 2);
        setUserAvatar(desiredUser.Avatarfile);
      }
    });
    sendGetRequest("getCategories.php").then((response) => {
      setCategories(response)
      if (queryCategory !== null) {
        let desiredCategory = response.find(
          (category) => category.id === queryCategory
        );
        updateChipState(desiredCategory.Category, 4);
        setCategory(desiredCategory.Category, 4);
      }
    });
    sendGetRequest("getAllCourses.php").then((response) => {
      setCourses(response)
      if (queryCourse !== null) {
        let desiredCourse = response.find(
          (course) => course.id === queryCourse
        );
        updateChipState(desiredCourse.Kurs, 1);
      } 
    });
    sendGetRequest("getSurveys.php").then((response) => {
      setSurveys(response)
      if (querySurvey !== null) {
        let desiredSurvey = response.find(
          (survey) => survey.id === querySurvey
        );
        updateChipState(desiredSurvey.Survey, 5);
      } 
    });
  }, []);

  React.useEffect(() => {
    if (props.filter.hasOwnProperty("trainer") && users.length > 0) {
      if (props.filter.trainer !== "") {
        let desiredUser = users.find(
          (user) => user.ID === props.filter.trainer
        );
        updateChipState(desiredUser.Vorname + " " + desiredUser.Nachname, 2);
        setName(desiredUser.Vorname + " " + desiredUser.Nachname, 2);
        setUserAvatar(desiredUser.Avatarfile);
      } else {
        updateChipState(props.filter.trainer, 2);
      }
    }
    if (props.filter.hasOwnProperty("category") && categories.length > 0) {
      if (props.filter.category !== "") {
        let desiredCategory = categories.find(
          (category) => category.id === props.filter.category
        );
        updateChipState(desiredCategory.Category, 4);
        setCategory(desiredCategory.Category, 4);
      } else {
        updateChipState(props.filter.category, 4);
      }
    }
    if (courses.length > 0) {
      if (props.filter.course !== "") {
        let desiredCourse = courses.find(
          (course) => course.id === props.filter.course
        );
        updateChipState(desiredCourse.Kurs, 1);
      } else {
        updateChipState(props.filter.course, 1);
      }
    }

    if (surveys.length > 0) {
      if (props.filter.survey !== "") {
        let desiredSurvey = surveys.find(
          (survey) => survey.id === props.filter.survey
        );
        updateChipState(desiredSurvey.Survey, 5);
      } else {
        updateChipState(props.filter.survey, 5);
      }
    }

    if (props.questionList !== undefined) {
      let filterQuestion = getQuestionDescription(
        props.questionList,
        props.filter.question
      );
      updateChipState(filterQuestion, 0);
    }
    updateChipState(props.filter.startDate + " - " + props.filter.endDate, 3);
  }, [props.filter, props.questionList]);

  const updateChipState = (value, key) => {
    setChipData((prevChipData) => {
      return prevChipData.map((chip) =>
        chip.key === key ? { ...chip, label: value } : chip
      );
    });
  };

  const navigate = useNavigate();
  const handleDelete = (chipToDelete) => () => {
    if (chipToDelete.key === 0) {
      updateState("question", "", props.setFilter);
    }
    if (chipToDelete.key === 1) {
      updateState("course", "", props.setFilter);
    }
    if (chipToDelete.key === 2) {
      updateState("trainer", "", props.setFilter);
    }
    if (chipToDelete.key === 4) {
      updateState("category", "", props.setFilter);
    }
    if (chipToDelete.key === 5) {
      updateState("survey", "", props.setFilter);
    }
  };

  return (
    <>
      {chipData.map((data) => {
        let icon;

        if (data.key === 0) {
          icon = <HelpTwoToneIcon />;
        } else if (data.key === 1) {
          icon = <LocalLibraryTwoToneIcon />;
        } else if (data.key === 2 && data.label !== "") {
          icon = (
            <UserAvatar
              name={name}
              src={userAvatar}
              marginLeft={"10px"}
              marginRight={"0px"}
              width="30px"
              height="30px"
            />
          );
        }
        else if (data.key === 4) {
          icon = <CategoryTwoToneIcon />;
        }        
        else if (data.key === 5) {
          icon = <AssignmentTwoToneIcon />;
        }

        return (
          <Chip
            key={data.key}
            icon={icon}
            label={data.label}
            color="primary"
            variant="outlined"
            onDelete={handleDelete(data)}
            style={{
              fontSize: "15px",
              padding: "18px 0px",
              maxWidth: "330px",
              backgroundColor: "white",
              display:
                data.label === "" ||
                (data.key === 0 && props.view === "Formulare")
                  ? "none"
                  : "",
            }}
          />
        );
      })}
    </>
  );
}
