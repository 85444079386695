import titleimage from "../../Assets/evaluation.png"
import { introConfig } from "./introConfig";

export function evaluationIntro(target) {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: target+" Fragen Auswertung  <img src="+titleimage+" width=35 height=35/>",
        intro: "Erhalte eine detailierte Auswertung des Kursteilnehmer Feedbacks zu einzelnen Fragen",
      },
      {
        title: "Filter 🚩",
        element: document.querySelector("#filter-button"),
        intro: "Klicke hier um das abgegebene Feedback nach Trainer, Kursen, Fragen und einem bestimmten Zeitraum zu filtern",
      },
      {
        title: "Fragen Auswertung 📈",
        element: document.querySelector(".MuiDataGrid-root"),
        intro: "In einer Tabelle werden alle Fragen zu denen Feedback abgegeben wurde gelistet. Durch Klick auf Details wird eine Auswertung der gewählten Frage geladen",
      },
    ],
  };
}
