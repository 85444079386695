import { validateSSOCredentials } from "./validateSSOCredentials";

export const HandleSSOSubmit = (account, idToken, navigate) => {
  console.log("idToken", idToken);
  const email = account.username;
  const name = account.name;
  validateSSOCredentials({ email, idToken }).then(function (isValid) {
    if (isValid === true) {
      localStorage.setItem("loggedInUser", email);
      localStorage.setItem("isLoggedIn", true);
      navigate("/");
    } else if (isValid === "user not registered") {
      localStorage.setItem("loggedInUser", email);
      navigate("/newuser?email=" + email+ "&name=" + name);
    } else {
      navigate("/login");
    }
  });
};
