import { toast } from "react-toastify";
import { baseURL } from "./baseURL.js";

export const sendGetRequestWoToken = async (endpoint, data) => {
  const endpointUrl = baseURL + endpoint;

  try {
    const res = await fetch(endpointUrl, {
      body: JSON.stringify(data),
    });

    const Response = await res.json();

    if (!res.ok) {
      toast.error(
        "Ein unbekannter Fehler ist aufgetreten"
      );
    }

    return Response;
  } catch (error) {
    if (!error.message.includes("Token")) {
      toast.error(
        "Ein Fehler ist aufgetreten - überprüfe deine Internetverbindung "
      );
    }
    throw error;
  }
};
