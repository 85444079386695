import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { sendGetRequest } from "../../Common/sendGetRequest";
import { updateState } from "../Surveys/surveysFunctions";

const CourseSelectWithoutKeine = (props) => {
  const emptyObject = {
    id: "",
    label: "",
  }
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(emptyObject);

  useEffect(() => {
    sendGetRequest("getCourses.php").then((response) => {
      setOptions(response);
    });
  }, []);

  useEffect(() => {
      let currentCourseChoice = options.filter(option => option.id == props.courseId )
      if(currentCourseChoice.length>0)
      {
        setValue({
          id: currentCourseChoice[0].id,
          label: currentCourseChoice[0].Kurs,
        })
      }
  }, [props.courseId, options]);

  let menuItems = options.map((option) => ({
    id: option.id,
    label: option.Kurs,
  }));

  return (
      <Autocomplete
      id="courseSelect"
      style={{ width: props.width }}
      options={menuItems}
      value={value}
      inputValue={value.label}
      onChange={(event, newValue) => {
        props.setCourseId(newValue ? newValue.id : "");
        setValue(newValue ? newValue : emptyObject)
      }}   
      onInputChange={(event, newValue) => {
        updateState("label", newValue, setValue)
      }}   
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Kurs"
          variant="standard"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default CourseSelectWithoutKeine;
