import titleimage from "../../Assets/surveys_old.png"
import { introConfig } from "./introConfig";

export function surveyAdminIntro() {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: "Fragebögen <img src="+titleimage+" width=35 height=35/>",
        intro: 'Verwalte die Fragebögen deines Systems indem du neue hinzufügst, Daten zu bestehenden Fragebögen bearbeitest oder Fragebögen löscht',
      },
      {
        title: "Fragebögentabelle",
        element: document.querySelector(".MuiDataGrid-root"),
        intro: "In einer Tabelle werden alle gespeicherten Fragebögen gelistet. Über das Suchfeld wird auf bestimmte Fragebögen eingeschränkt",
      },     
      {
        title: "Menu 🚩",
        element: document.querySelector("#menu-button"),
        intro: "Klicke hier um den gewählten Fragebogen zu bearbeiten oder zu löschen.",
      },
    ],
  };
}
