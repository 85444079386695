export const TimeLineDiagram = {
  series: [],
  options: {
    chart: {
      stacked: false,
      height: 350,
      zoom: {
        enabled: false,
      },
    },

    plotOptions:{
      bar:{
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: 3,
    },
    title: {
      text: "Timeline abgegebenes Feedback",
      align: "left",
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          day: "",
        },
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: "bottom",
    },
    responsive: [{
      breakpoint: 767,
      options: {
        chart: {
          zoom: {
            enabled: false // Hide zoom toolbar for small screens
          }
        }
      }
    }]
  },
};
