import titleimage from "../../Assets/group.png"
import optionsimage from "../../Assets/user-options.png"
import { introConfig } from "./introConfig";

export function userIntro() {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: "Benutzerliste  <img src="+titleimage+" width=35 height=35/>",
        intro: "Verwalte die Benutzer deines Systems indem du neue Benutzer hinzufügst, bestehende Benutzerdaten bearbeitest oder bestehende User löscht",
      },
      {
        title: "Benutzertabelle",
        element: document.querySelector(".MuiDataGrid-root"),
        intro: "In einer Tabelle werden alle gespeicherten Benutzer gelistet. Über das Suchfeld wird auf bestimmte Benutzer eingeschränkt",
      },
      {
        title: "Rollen Trainer / Administrator",
        element: document.querySelector('div[data-field="Role"]'),
        intro: "Jedem Benutzer können die Rollen Trainer, Administrator oder beides zugewiesen werden. Trainer haben nur eingeschränkte Möglichkeiten und können keine Auswertungen anderer Trainer erzeugen ",
      },      
      {
        title: "Menu 🚩",
        element: document.querySelector("#menu-button"),
        intro: "Klicke hier um zusätzliche Optionen zum gewählten Benutzer zu erhalten.<br/><br/><img src="+optionsimage+" width=300 height=230/>",
      },
    ],
  };
}
