import titleimage from "../../Assets/dashboard.png";
import { introConfig } from "./introConfig";

export function dashboardIntro() {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: "Herzlich Willkommen auf deinem Feedcube Dashboard 👋",
        intro:
          "Hier erhältst du einen guten Überblick über Feedback das zu Kursen und Trainern abgegeben wurde",
      },
      {
        title: "Filter 🚩",
        element: document.querySelector("#filter-button"),
        intro:
          "Klicke hier um das abgegebene Feedback nach Trainer, Kursen, Kategorien und einem bestimmten Zeitraum zu filtern",
      },
      {
        title: "Zielgruppe 🎯",
        element: document.querySelector("#target-button"),
        intro:
          "Wähle die Zielgruppe (Kursteilnehmer oder Trainer) deren Feedback du auswerten möchtest",
      },
      {
        title: "Auswertung <img src=" + titleimage + " width=35 height=35/>",
        element: document.querySelector(".MuiGrid-container"),
        intro:
          "In diesem Bereich wird abgegebenes Feedback automatisiert ausgewertet und visuell in Form von Diagrammen dargestellt",
      },
      {
        title: "Hilfe ❓",
        element: document.querySelector("#help-button"),
        intro:
          "Wird eine Erklärung zu einer Seite benötigt, kann über diesen Button jederzeit eine kurze Anleitung gestartet werden",
      },
    ],
  };
}
