import React from "react";
import { Comments } from "../Evaluation/Comments";
import "../Evaluation/evaluation.css";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { DonutDiagram } from "../../Diagrams/DonutDiagram";
import Tooltip from "@mui/material/Tooltip";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import surveysImage from "../../../Assets/surveys.png";
import teacherImage from "../../../Assets/teacher.png";
import categoryImage from "../../../Assets/classification.png";
import graduationHatImage from "../../../Assets/graduation-hat.png";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import {
  FaChartColumn,
  FaChartLine,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import {
  Next,
  Prev,
  berechneDurchschnittBewertung,
  calculateDaysSinceLastFeedback,
  countNumberOfCourses,
  countNumberOfFeedback,
  countNumberOfTrainers,
  createObjectForCoursePieChart,
  createObjectForTimelineChart,
  createObjectForTrainerPieChart,
  createSeriesForCoursePieChart,
  createSeriesForEvaluationPieChart,
  createSeriesForRadialChart,
  createSeriesForTrainerPieChart,
  getNumberForTimelineChart,
} from "./dashboardEvaluationFunctions";
import Empty from "../../../Common/Empty";
import TrainerDiagram from "../Evaluation/Diagrams/TrainerDiagram";
import CoursesDiagram from "../Evaluation/Diagrams/CoursesDiagram";
import BewertungTimelineDiagram from "../Evaluation/Diagrams/BewertungTimelineDiagram";
import { RadialDiagram } from "../../Diagrams/RadialDiagram";
import "./dashboard.css";
import Alert from "@mui/material/Alert";
import FeedbackTimeline from "./FeedbackTimeline";
import { useNavigate } from "react-router-dom";
import BewertungBarDiagram from "../Evaluation/Diagrams/BewertungBarDiagram";
import CategoryIcon from "@mui/icons-material/Category";

export default function DashboardEvaluationModule(props) {
  const [seriesForEvaluationPieChart, setSeriesForEvaluationPieChart] =
    useState([]);
  const [seriesForRadialChart, setSeriesForRadialChart] = useState([]);
  const [seriesForTrainerPieChart, setSeriesForTrainerPieChart] = useState([]);
  const [labelsForTrainerPieChart, setLabelsForTrainerPieChart] = useState([]);
  const [seriesForCoursePieChart, setSeriesForCoursePieChart] = useState([]);
  const [labelsForCoursePieChart, setLabelsForCoursePieChart] = useState([]);
  const [seriesForTimelineChart, setSeriesForTimelineChart] = useState([]);
  const [numberForTimelineChart, setNumberForTimelineChart] = useState([]);
  const [average, setAverage] = useState(undefined);
  const [numberOfFeedback, setNumberOfFeedback] = useState(0);
  const [numberOfTrainers, setNumberOfTrainers] = useState(0);
  const [numberOfCourses, setNumberOfCourses] = useState(0);
  const [timestampLatestFeedback, setTimestampLatestFeedback] =
    useState(undefined);
  const [daysSinceLastFeedback, setDaysSinceLastFeedback] = useState("");
  const navigate = useNavigate();
  const [diagramState, setDiagramState] = useState("Categories");

  let newRadial = RadialDiagram;
  const [lineView, setLineView] = useState(true);

  useEffect(() => {
    if (props.feedbackData.length != 0) {
      setAverage(berechneDurchschnittBewertung(props.feedbackData));
      setNumberOfFeedback(countNumberOfFeedback(props.feedbackData));
      setNumberOfTrainers(countNumberOfTrainers(props.feedbackData));
      setNumberOfCourses(countNumberOfCourses(props.feedbackData));
      setTimestampLatestFeedback(props.feedbackData[0].Date);
      setDaysSinceLastFeedback(
        calculateDaysSinceLastFeedback(props.feedbackData)
      );
      setSeriesForEvaluationPieChart(
        createSeriesForEvaluationPieChart(props.feedbackData)
      );
      setSeriesForRadialChart(
        createSeriesForRadialChart(props.feedbackData, newRadial)
      );
      let trainerObject = createObjectForTrainerPieChart(props.feedbackData);
      trainerObject = createSeriesForTrainerPieChart(trainerObject);
      setLabelsForTrainerPieChart(trainerObject.labels);
      setSeriesForTrainerPieChart(trainerObject.series);

      let coursesObject = createObjectForCoursePieChart(props.feedbackData);
      coursesObject = createSeriesForCoursePieChart(coursesObject);

      setLabelsForCoursePieChart(coursesObject.labels);
      setSeriesForCoursePieChart(coursesObject.series);

      const timelineObject = createObjectForTimelineChart(props.feedbackData);
      setSeriesForTimelineChart(timelineObject);

      const numberForTimelineArray = getNumberForTimelineChart(
        props.feedbackData
      );
      setNumberForTimelineChart(numberForTimelineArray);
    }
  }, [props.feedbackData]);

  let evaluationLink =
    props.target === "Kursteilnehmer"
      ? "../evaluation/Kursteilnehmer/surveys"
      : "../evaluation/Intern/surveys";

  function handleLinkClick(page) {
    navigate("../" + page);
  }

  function navigateToSurveyOverview() {
    navigate(evaluationLink);
  }

  return (
    <>
      {props.feedbackData.length != 0 ? (
        <>
          <Grid item xs={12} lg={8.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Fade in={true}>
                  <Paper
                    className="paper link"
                    onClick={() => navigateToSurveyOverview()}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Stack direction="column">
                        <header>Beurteilte Fragebögen</header>
                        <h2>{numberOfFeedback}</h2>
                      </Stack>
                      <img
                        className="pageIconDashboard"
                        src={surveysImage}
                        alt="checkmark"
                      />
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Fade in={true}>
                  <Paper
                    className="paper link"
                    onClick={() => handleLinkClick("users")}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Stack direction="column">
                        <header>Beurteilte Trainer</header>
                        <h2>{numberOfTrainers}</h2>
                      </Stack>
                      <img
                        className="pageIconDashboard"
                        src={teacherImage}
                        alt="checkmark"
                      />
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Fade in={true}>
                  <Paper
                    className="paper link"
                    onClick={() => handleLinkClick("courses")}
                  >
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Stack direction="column">
                        <header>Beurteilte Kurse</header>
                        <h2>{numberOfCourses}</h2>
                      </Stack>
                      <img
                        className="pageIconDashboard"
                        src={graduationHatImage}
                        alt="checkmark"
                      />
                    </Stack>
                  </Paper>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="paper">
                  <ReactApexChart
                    options={DonutDiagram.options}
                    series={seriesForEvaluationPieChart}
                    height={360}
                    type="donut"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className="paper">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      paddingLeft: "10px",
                    }}
                  >
                    <header>
                      {diagramState === "Categories" &&
                        "Bewertung nach Kategorien"}{" "}
                      {diagramState === "Trainer" &&
                        (props.target === "Kursteilnehmer"
                          ? "Welche Trainer wurden bewertet?"
                          : "Welche Benutzer haben bewertet?")}
                      {diagramState === "Courses" &&
                        "Welche Kurse wurden bewertet?"}
                    </header>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      style={{
                        width: "100px",
                      }}
                    >
                      <Tooltip title="Kategorien">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramState("Categories")}
                        >
                          <CategoryIcon
                            fontSize="small"
                            color={
                              diagramState === "Categories"
                                ? "primary"
                                : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Trainer">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramState("Trainer")}
                        >
                          <SchoolIcon
                            fontSize="small"
                            color={
                              diagramState === "Trainer" ? "primary" : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Kurse">
                        <IconButton
                          className="navigator"
                          size="small"
                          onClick={() => setDiagramState("Courses")}
                        >
                          <LocalLibraryIcon
                            fontSize="small"
                            color={
                              diagramState === "Courses" ? "primary" : "default"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  {diagramState === "Categories" &&
                    (seriesForRadialChart.length > 0 ? (
                      <ReactApexChart
                        options={newRadial.options}
                        series={seriesForRadialChart}
                        height={330}
                        type="radialBar"
                      />
                    ) : (
                      <Stack
                        className="link"
                        direction="column"
                        height={340}
                        justifyContent={"start"}
                        alignItems={"center"}
                        spacing={4}
                        onClick={() => navigate("../categories")}
                      >
                        <Alert severity="warning">
                          Es wurden noch keine Bewertungen für Fragen einer
                          Kategorie abgegeben
                        </Alert>
                        <img
                          style={{ width: "200px", height: "200px" }}
                          src={categoryImage}
                        ></img>
                      </Stack>
                    ))}
                  {diagramState === "Trainer" && (
                    <TrainerDiagram
                      labelsForTrainerPieChart={labelsForTrainerPieChart}
                      seriesForTrainerPieChart={seriesForTrainerPieChart}
                      size={330}
                    />
                  )}
                  {diagramState === "Courses" && (
                    <CoursesDiagram
                      labelsForCoursePieChart={labelsForCoursePieChart}
                      seriesForCoursePieChart={seriesForCoursePieChart}
                      size={330}
                    />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3.5}>
            <Paper className="paper" style={{ height: "465px" }}>
              <header>Feedback Timeline</header>
              <FeedbackTimeline
                data={props.feedbackData}
                target={props.target}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4.5}>
            <Comments feedbackData={props.feedbackData} />
          </Grid>
          <Grid item xs={12} lg={7.5}>
            <Fade in={true}>
              <Paper
                className="paper"
                style={{ paddingBottom: "0px", position: "relative" }}
              >
                {seriesForTimelineChart.length > 1 && (
                  <Tooltip
                    title={lineView ? "Balkendiagramm" : "Liniendiagramm"}
                  >
                    <IconButton
                      className="navigator"
                      size="small"
                      style={{
                        position: "absolute",
                        right: "60px",
                        top: "12.5px",
                      }}
                      onClick={() => setLineView(!lineView)}
                    >
                      {lineView ? <FaChartColumn /> : <FaChartLine />}
                    </IconButton>
                  </Tooltip>
                )}
                {seriesForTimelineChart.length > 1 && lineView ? (
                  <BewertungTimelineDiagram
                    seriesForTimelineChart={seriesForTimelineChart}
                    numberForTimelineChart={numberForTimelineChart}
                    type={"area"}
                    average={average}
                    max={5}
                  />
                ) : (
                  <BewertungBarDiagram
                    seriesForTimelineChart={seriesForTimelineChart}
                    numberForTimelineChart={numberForTimelineChart}
                    type={"bar"}
                    average={average}
                    max={5}
                  />
                )}
              </Paper>
            </Fade>
          </Grid>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}
