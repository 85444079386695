import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { sendGetRequestWoToken } from "./components/Common/sendGetRequestWoToken";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/Common/ErrorPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
sendGetRequestWoToken("getSystemInfos.php").then((decoded) => {
  const Theme = createTheme({
    palette: {
      primary: {
        main: decoded.ThemeColor,
      },
    },
  });
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={Theme}>
        <ErrorBoundary
          FallbackComponent={ErrorPage}
        >
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
