import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";
import TranscribeIcon from '@mui/icons-material/Transcribe';

export default function TargetButton(props) {

  return (
    <ButtonGroup
      style = {{width:"100px"}}
      id="target-button"
      variant="outlined"
      aria-label="outlined primary button group"
    >
      <Tooltip title="Kursteilnehmer Feedback">
        <Button
          variant={props.target === "Kursteilnehmer" ? "contained" : "outlined"}
          onClick={() => props.setTarget("Kursteilnehmer")+props.setAnonym(true)}
        >
          <FaUserGraduate size={"20px"}/>
        </Button>
      </Tooltip>
      <Tooltip title="Internes Feedback">
      <Button
        variant={props.target === "Intern" ? "contained" : "outlined"}
        onClick={() => props.setTarget("Intern")+props.setAnonym(true)}
      >
        <TranscribeIcon size={"20px"}/>
      </Button>
      </Tooltip>
    </ButtonGroup>
  );
}
