import React from "react";
import { useNavigate } from "react-router-dom";
import msalInstance from "./authConfig";
import { getCookie } from "../Common/getCookie";
import { HandleSSOSubmit } from "./handleSSOSubmit";

export function useSSOLogin() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedInUser");
    const jwtTokenCookie = getCookie("jwt");
    if (!jwtTokenCookie || jwtTokenCookie === "null") {
      const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      if (isLoggedIn) {
        msalInstance.initialize().then(() => {
          msalInstance
            .ssoSilent({
              scopes: ["User.Read"],
              loginHint: loggedInUser,
            })
            .then((response) => {
              console.log("HandleSSO:", response);
              const account = response.account;
              const idToken = response.idToken;
              HandleSSOSubmit(account, idToken, navigate);
            })
            .catch((error) => {
              console.info("SSO Silent fehlgeschlagen:", error);
              navigate("/");
            });
        });
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  return "";
}
