import React from "react";
import { TagCloud } from "react-tagcloud";
import Tooltip from "@mui/material/Tooltip";

export const customRenderer = (tag, size, color) => {
  const { className, style, ...props } = tag.props || {}
  const fontSize = size + 'px'
  const key = tag.key || tag.value
  const tagStyle = { ...styles, color, fontSize, ...style }

  let tagClassName = 'tag-cloud-tag'
  if (className) {
    tagClassName += ' ' + className
  }

  return (
    <Tooltip title={tag.value + ": "+tag.count}>
    <span className={tagClassName} style={tagStyle} key={key} {...props}>
      {tag.value}
    </span>
    </Tooltip>
  )
}

const styles = {
  margin: '0px 3px',
  verticalAlign: 'middle',
  display: 'inline-block',
}

export default function WordCloud(props) {
  return (
      <TagCloud
        minSize={12}
        maxSize={35}
        tags={props.data}
        renderer={customRenderer}
        shuffle={true} 
      />
  );
}
