import { useContext, useEffect } from "react";
import { ActiveNavigationContext } from "./Sidebar";
import { setCookie } from "../../Common/setCookie";

export function useActiveNavigation(activeNavigationNew) {
  const expirationDate = new Date(Date.now() + 86400 * 1000); // Set the expiration date to 1 day from the current time

  const { activeNavigation, setActiveNavigation } = useContext(
    ActiveNavigationContext
  );
  useEffect(() => {
    if (
      activeNavigation.activePage !== activeNavigationNew.activePage ||
      activeNavigation.activeSubPage !== activeNavigationNew.activeSubPage
    ) {
      setActiveNavigation({
        activePage: activeNavigationNew.activePage,
        activeSubPage: activeNavigationNew.activeSubPage,
        activeModule: activeNavigationNew.activeModule
      });
      setCookie("module", activeNavigationNew.activeModule, expirationDate)
    }
  });
}
