import React from "react";
import "./Login.css";
import Spinner from "../../Common/Spinner";

const LoginFallback = () => {

  return (
    <>
    <Spinner/>
    </>
  );
};

export default LoginFallback;
