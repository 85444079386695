import React, { useEffect, useState } from "react";
import "./evaluation.css";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import { FaCloud, FaComments } from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import WordCloud from "./WordCloud";
import Tooltip from "@mui/material/Tooltip";
import AIReport from "./AIReport";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { countSpecificWords } from "./evaluationFunctions";
import { formatDate } from "../Dashboard/dashboardEvaluationFunctions";

export function Comments({ feedbackData }) {
  const [report, setReport] = useState("");
  const [reportVisibility, setReportVisibility] = useState(false);
  const [wordCloudData, setWordCloudData] = useState("");
  const [wordCloudVisible, setWordCloudVisible] = useState(false);

  let allComments = feedbackData.filter(
    (comment) => comment.Typ === "Text"
  );

  useEffect(() => setWordCloudData(countSpecificWords(allComments)),[]);
  return (
    <Fade in={true}>
      <Paper
        className="paper"
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          maxHeight: "750px",
          overflowY: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{
            width: "100%",
          }}
        >
          <header>Kommentare</header>
          <Stack direction="row" spacing={2}>
            <Tooltip title="KI-Report">
              <IconButton
                className="navigator"
                size="small"
                onClick={() => setReportVisibility(true)}
              >
                <AutoAwesomeIcon />
              </IconButton>
            </Tooltip>
            {wordCloudVisible ? (
              <Tooltip title="Kommentare">
                <IconButton
                  className="navigator"
                  size="small"
                  onClick={() => {
                    setWordCloudVisible(!wordCloudVisible);
                    setReportVisibility(false);
                  }}
                >
                  <FaComments />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Wordcloud">
                <IconButton
                  className="navigator"
                  size="small"
                  onClick={() => {
                    setWordCloudVisible(!wordCloudVisible);
                    setReportVisibility(false);
                  }}
                >
                  <FaCloud />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        {reportVisibility ? (
          <AIReport
            report={report}
            setReport={setReport}
            comments={allComments
              .map(
                (formData) =>
                  "Trainer: " +
                  formData.TrainerName +
                  ",Kurs: " +
                  formData.CourseName +
                  ",Datum: " +
                  formatDate(formData.Date) +
                  ",Frage: " +
                  formData.Fragenbeschreibung +
                  ",Kommentar: " +
                  formData.Value.slice(1, -1)
              )
              .join(" ")}
          />
        ) : !wordCloudVisible ? (
          <>
            {allComments.map((formData) => (
              <Stack
                direction="column"
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <h6>{formData.TrainerName}</h6>
                <h6>{formData.CourseName}</h6>
                <p>{formatDate(formData.Date)}</p>
                <p>{formData.Fragenbeschreibung}</p>
                <p
                  style={{
                    fontSize: "17px",
                  }}
                >
                  {formData.Value.slice(1, -1)}
                </p>
                <hr />
              </Stack>
            ))}
          </>
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {" "}
            <WordCloud data={wordCloudData} />
          </div>
        )}
      </Paper>
    </Fade>
  );
}
