import React from "react";
import Avatar from "@mui/material/Avatar";
import userImage from "../../Assets/user.png"

const UserAvatar = (props) => {

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name, avatarfile) {
    if ((name && name.length>1) || avatarfile) {
      if (avatarfile) {
        let url = avatarfile;
        return {
          src: url,
          sx: { width: props.width, height: props.height },
        };
      } else if (
        name.indexOf(" ") !== -1 &&
        name.indexOf(" ") !== name.length - 1
      ) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width: props.width,
            height: props.height,
          },
          children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
      } else {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width: props.width,
            height: props.height,
          },
          children: name[0],
        };
      }
    }
    else{
      let url = userImage
      return {
        src: url,
        sx: { width: props.width, height: props.height },
      };
    }
  }
  return (
    <Avatar
      {...stringAvatar(props.name, props.src)}
      style={{
        marginRight: props.marginRight,
        marginLeft: props.marginLeft,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
        width: props.width,
        height: props.height,
      }}
    />
  );
};

UserAvatar.defaultProps = {
  height: "45px",
  width: "45px",
  marginRight: "auto",
  marginLeft: "auto",
  marginTop: "0px",
  marginBottom: "0px",
};
export default UserAvatar;
