import React from "react";
import CommonSidebar from "../Portal/Sidebar/Sidebar";
import "../Portal/Sidebar/style.css";

const Page = (props) => {

  return (
    <div className="page">
      <CommonSidebar>
      <div
        className="main-content"
      >
      {props.children}
      </div>
      </CommonSidebar>
    </div>
  );
};

export default Page;
