import React from "react";
import "../Evaluation/evaluation.css";
import { useContext } from "react";
import { UserContext } from "../../Users/UserContext";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import evaluationImage from "../../../Assets/dashboard.png";
import Filter from "../Filter/Filter";
import Spinner from "../../../Common/Spinner";
import TargetButton from "./TargetButton";
import FilterChips from "../Filter/FilterChips";
import Grid from "@mui/material/Grid";
import Empty from "../../../Common/Empty";
import DashboardEvaluationModule from "./DashboardEvaluationModule";
import { useLocation } from "react-router-dom";
import {
  filterFeedbackData,
  loadFeedbackData,
} from "../Evaluation/evaluationFunctions";
import { useActiveNavigation } from "../../Sidebar/useActiveNavigation";

export default function Dashboard() {
  const location = useLocation();
  const [dataFetched, setDataFetched] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const { currentUserData } = useContext(UserContext);
  const currentYear = new Date().getFullYear();
  const firstDayOfYear = new Date(currentYear, 0, 1);
  const [filter, setFilter] = useState({
    category: queryParams.get("categrory") ? queryParams.get("category") : "",
    survey: queryParams.get("survey") ? queryParams.get("survey") : "",
    trainer: queryParams.get("trainer") ? queryParams.get("trainer") : "",
    course: queryParams.get("course") ? queryParams.get("course") : "",
    startDate: firstDayOfYear,
    endDate: new Date(),
  });
  const [target, setTarget] = useState("Kursteilnehmer");
  const [activeIndex, setActiveIndex] = useState(0);
  const [allFeedbackData, setAllFeedbackData] = useState([]);
  useActiveNavigation({ activePage: "dashboard", activeSubPage: "", activeModule: "Feedback" });

  useEffect(() => {
    if (currentUserData.id !== 0) {
      loadFeedbackData(setDataFetched, currentUserData, setAllFeedbackData);
    }
  }, [currentUserData]);

  const feedbackData = filterFeedbackData(allFeedbackData, filter, target);

  if (currentUserData.id === 0) {
    return <Spinner />;
  }

  return (
    <>
      <ToastContainer />
      <Stack
        className="header"
        direction="row"
        spacing={{ xs: 2, sm: 1, md: 2 }}
      >
        <img className="pageIcon" src={evaluationImage} alt="checkmark" />
        <div>
          <h5>Dashboard</h5>
          <p className="siteInfo">
            Erhalte einen umfassenden Überblick über abgegebenes Feedback
          </p>
        </div>
      </Stack>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"start"}
        className="filter"
      >
        <Stack
          direction="row"
          alignItems={{ xs: "start", sm: "center" }}
          justifyContent={"start"}
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          style={{ minWidth: "70%" }}
        >
          <Filter
            filter={filter}
            setFilter={setFilter}
            setActiveIndex={setActiveIndex}
            target={target}
          />
          <FilterChips filter={filter} setFilter={setFilter} />
        </Stack>
        <TargetButton target={target} setTarget={setTarget} />
      </Stack>
      {dataFetched ? (
        feedbackData.length !== 0 ? (
          <>
            <Grid container spacing={2}>
              <DashboardEvaluationModule
                target={target}
                filter={filter}
                setFilter={setFilter}
                feedbackData={feedbackData}
              />
            </Grid>
          </>
        ) : (
          <Empty />
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}
