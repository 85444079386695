import React, { useContext, useEffect } from "react";
import { UserContext } from "../Users/UserContext";
import { Autocomplete, TextField } from "@mui/material";
import { sendGetRequest } from "../../Common/sendGetRequest";
import UserAvatar from "../Users/UserAvatar";
import { updateState } from "../Surveys/surveysFunctions";
import Box from "@mui/material/Box";

const TrainerSelect = (props) => {
  const { currentUserData } = useContext(UserContext);
  const emptyObject = {
    id: "",
    label: "",
    Avatarfile: "",
  };
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(emptyObject);

  useEffect(() => {
    sendGetRequest("getTrainers.php").then((response) => {
      setOptions(response);
    });
  }, []);
  
  useEffect(() => {
      let currentTrainerChoice = options.filter(
        (option) => option.ID == props.trainerId
      );
      if (currentTrainerChoice.length > 0) {
        setValue({
          id: currentTrainerChoice[0].ID,
          label:
            currentTrainerChoice[0].Vorname +
            " " +
            currentTrainerChoice[0].Nachname,
          Avatarfile: currentTrainerChoice[0].Avatarfile,
        });
      }
  }, [props.trainerId, options]);

  let menuItems = options.map((option) => ({
    id: option.ID,
    label: option.Vorname + " " + option.Nachname,
    Avatarfile: option.Avatarfile,
  }));
  if (currentUserData.role < 2) {
    menuItems = [
      {
        id: currentUserData.id,
        label: currentUserData.vorname + " " + currentUserData.nachname,
        Avatarfile: currentUserData.avatarfile,
      },
    ];
    props.setTrainerId(currentUserData.id) // hier verbessern
  }
  return (
    <Autocomplete
      id="country-select-demo"
      style={{ width: props.width }}
      options={menuItems}
      value={value}
      inputValue={value.label}
      onChange={(event, newValue) => {
        props.setTrainerId(newValue ? newValue.id : "");
        setValue(newValue ? newValue : emptyObject);
      }}
      onInputChange={(event, newValue) => {
        updateState("label", newValue, setValue);
      }}
      disabled = {currentUserData.role < 2 ? true : false}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <UserAvatar
            name={option.label}
            src={option.Avatarfile}
            marginLeft={"10px"}
            marginRight={"10px"}
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Trainer"
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment: value.label && (
              <UserAvatar
                name={value.label}
                src={value?.Avatarfile} // You might want to replace this with the actual selected option's Avatarfile
                marginLeft={"10px"}
                marginRight={"10px"}
              />
            ),
          }}
        />
      )}
    />
  );
};

export default TrainerSelect;
