import { sendGetRequestWoToken } from "../../Common/sendGetRequestWoToken";
import { sendPostRequestWoToken } from "../../Common/sendPostRequestWoToken";

export const containsSpecialCharacters = (input) => {
  const specialCharactersRegex = /[!@#$%^&*(),.?"':{}|<>]/;
  return specialCharactersRegex.test(input);
};

export function createUser(
code,
setDataFetched,
setSuccess
) {
  sendPostRequestWoToken("createNewUser.php?code="+code).then((response) => {
    setDataFetched(true);
    if (response.includes("Benutzer wurde erfolgreich angelegt")) {
      setSuccess(true);
    }
    else{
      setSuccess(false);
    }
  });
}

export function sendVerificationEmail(
  newUserData,
  updateErrorState,
  setEmailErrorText,
  setLoading,
  setSuccess,
  navigate
) {
  setLoading(true);
  sendPostRequestWoToken("sendVerificationEmail.php", newUserData).then(
    (response) => {
      if (response.includes("Verification email sent successfully.")) {
        setSuccess(true);
        setLoading(false);
        return true;
      }
      else if (response.includes("email already taken")) {
        setEmailErrorText("Email bereits vergeben");
        updateErrorState("errorEmail", true);
        setLoading(false);
      }
      else if(response.includes("Ein unbekannter Fehler ist aufgetreten.")) {
        setLoading(false);
      }
    }
  )
  .catch((error) => {
    setLoading(false);
  });
}

export function getTrainerDetails(setTrainerName, trainerId, setDataFetched) {
  sendGetRequestWoToken("getUserDetails.php?id=" + trainerId).then(
    (response) =>
      setTrainerName(response.Vorname + " " + response.Nachname) +
      setDataFetched(true)
  );
}

export function getUserDetails(updateState, userId) {
  sendGetRequestWoToken("getUserDetails.php?id=" + userId).then(
    (response) =>
      updateState("name", response.Vorname + " " + response.Nachname) +
      updateState("vorname", response.Vorname) +
      updateState("nachname", response.Nachname) +
      updateState("email", response.Email) +
      updateState("role", response.Role) +
      updateState("description", response.Description) +
      updateState("avatarfile", response.AvatarFile)
  );
}

export async function getSSODomains(setSSODomains) {
  sendGetRequestWoToken("getSSODomains.php").then((response) => {
    setSSODomains(response ? response : [""]);
    return response;
  });
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  if (password.length < 6) {
    return false;
  }
  const passwordRegex = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
  return passwordRegex.test(password);
}

export async function validateInputFields(newUserData, setErrorNewUserData) {
  const errors = {};
  var allChanged = true;

  // Check if each variable has changed from initial state
  const promises = Object.entries(newUserData).map(([key, value]) => {
    if (key === "email" && !isValidEmail(value)) {
      allChanged = false;
      errors[`errorEmail`] = true;
    }
    if (key === "password" && !isValidPassword(value)) {
      allChanged = false;
      errors[`errorPassword`] = true;
    }
    if (
      key === "vorname" &&
      (value.length === 0 || containsSpecialCharacters(value))
    ) {
      allChanged = false;
      errors[`errorVorname`] = true;
    }
    if (
      key === "nachname" &&
      (value.length === 0 || containsSpecialCharacters(value))
    ) {
      allChanged = false;
      errors[`errorNachname`] = true;
    }
    return Promise.resolve();
  });

  await Promise.all(promises);
  if (!allChanged) {
    setErrorNewUserData((prevState) => ({
      ...prevState,
      ...errors,
    }));
  }
  return allChanged;
}

export function deleteValidationErrors(newUserData) {
  const errors = {};

  Object.entries(newUserData).forEach(([key, value]) => {
    errors[`error${key.charAt(0).toUpperCase()}${key.slice(1)}`] = false;
  });
}
