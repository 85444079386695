import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Typography from "@mui/material/Typography";
import UserAvatar from "../../Users/UserAvatar";
import Tooltip from "@mui/material/Tooltip";
import { formatDate } from "./dashboardEvaluationFunctions";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../../Common/setCookie";

export default function FeedbackTimeline({ data, target }) {
  const navigate = useNavigate();

  // Filter data to include only unique CoursefeedbackID
  const uniqueData = Array.from(
    new Set(data.map((item) => item.CoursefeedbackID))
  ).map((courseFeedbackID) =>
    data.find((item) => item.CoursefeedbackID === courseFeedbackID)
  );

  function navigateToSurveyDetails (surveyId){
    navigate("../evaluation/" + target + "/surveys/" + surveyId)    
  }

  return (
    <div style={{ height: "430px", overflowY: "auto", overflowX: "hidden" }}>
      <Timeline sx={{padding:"6px"}} position="left">
        {uniqueData.map((item, index) => (
          <Tooltip key={index} title={"Trainer: " + item.TrainerName}>
            <TimelineItem
              sx={{ cursor:"pointer", width:"110%", marginLeft:"-5%" }}
              onClick={() => navigateToSurveyDetails(item.CoursefeedbackID)}
            >
              <TimelineOppositeContent
                sx={{ m: "auto 0", width: "70px" }}
                variant="body2"
                color="text.secondary"
              >
                {formatDate(item.Date)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <UserAvatar name={item.TrainerName} src={item.Avatarfile} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ m: "auto", width: "120px" }}>
                <Typography variant="h6" component="span">
                  {item.CourseName}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Tooltip>
        ))}
      </Timeline>
    </div>
  );
}
