import { toast } from "react-toastify";

export function showToaster(response, errorMessage, successMessage) {
    if (response.includes(successMessage)) {
      toast.success(successMessage);
    } else {
      toast.error(errorMessage);
    }
  }

export function showCreatedToaster(){
  const storedToastMessage = localStorage.getItem("toastMessage");
  if (storedToastMessage) {
    showToaster(
      storedToastMessage,
      "Ein Fehler ist aufgetreten",
      storedToastMessage
      );
    localStorage.removeItem("toastMessage");
  }
}