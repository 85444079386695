import { PublicClientApplication } from "@azure/msal-browser";

const subdomain = window.location.hostname.split(".")[0];

const msalConfig = {
    auth: {
        clientId: "85b6a3ad-28f1-4580-a8e3-ec1bac456304", // Die Client-ID Ihrer Azure AD-Registrierung
        authority: "https://login.microsoftonline.com/common", // Multi-Mandanten-URL
        redirectUri: "https://"+subdomain+".feedcube.net", // Ruft die Launch Komponente auf
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true   
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
