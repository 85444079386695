import sidebarConfigFeedback from "./sidebarConfigFeedback.json";
import sidebarConfigCoworking from "./sidebarConfigCoworking.json";
import sidebarConfigAdministration from "./sidebarConfigAdministration.json";

export default function getSidebarConfig(module) {
  if (module === "Feedback") {
    return sidebarConfigFeedback;
  } else if (module === "Coworking") {
    return sidebarConfigCoworking;
  } else if (module === "Administration") {
    return sidebarConfigAdministration;
  }
}
