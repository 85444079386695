import { toast } from "react-toastify";
import { baseURL } from "./baseURL.js";
import { getCookie } from "./getCookie.js";

export const sendPostRequest = async (endpoint, data) => {
  const jwt = getCookie("jwt");
  const endpointUrl = baseURL + endpoint;
  
  try {
  const res = await fetch(endpointUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(data)
  });
  const Response = await res.text();
  if (!res.ok) {
    toast.error(
      "Ein unbekannter Fehler ist aufgetreten"
    );
  }
  return Response;

}
catch (error) {
  if (!error.message.includes("Token")) {
    toast.error(
      "Ein Fehlers ist aufgetreten, überprüfe deine Internetverbindung"
    );
  }
  throw error;
}
};

