import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import "./components/Portal/Layout/layout.css";
import "bootstrap/dist/css/bootstrap.css";
import { getCookie } from "./components/Common/getCookie";
import { setCookie } from "./components/Common/setCookie";
import PrivateRoutes from "./components/Common/PrivateRoutes";
import LoginFallback from "./components/Portal/Login/LoginFallback";
import Dashboard from "./components/Portal/Feedback/Dashboard/Dashboard";
import Register from "./components/Portal/Login/Register";

const AdminRoutes = lazy(() => import("./components/Common/AdminRoutes"));
const NoPermission = lazy(() => import("./components/Portal/NoPermission/NoPermission"));
const Launch = lazy(() => import("./components/Portal/Login/Launch"));
const Login = lazy(() => import("./components/Portal/Login/Login"));
const PasswordReset = lazy(() => import("./components/Portal/Login/PasswordReset"));
const PasswordResetConfirm = lazy(() => import("./components/Portal/Login/PasswordResetConfirm"));
const CreateNewUser = lazy(() => import("./components/Portal/Login/CreateNewUser"));
const Pending = lazy(() => import("./components/Portal/Login/Pending"));
const Survey = lazy(() => import("./components/Survey/Survey"));

const Boards = lazy(() => import("./components/Portal/Boards/Boards"));
const CreateBoard = lazy(() => import("./components/Portal/Boards/CreateBoard"));
const EditBoard = lazy(() => import("./components/Portal/Boards/EditBoards"));

const Evaluation = lazy(() => import("./components/Portal/Feedback/Evaluation/Evaluation"));
const Profile = lazy(() => import("./components/Portal/Profile/Profile"));

const FeedbackLink = lazy(() => import("./components/Portal/Link/FeedbackLink"));
const Courses = lazy(() => import("./components/Portal/Courses/Courses"));
const EditCourses = lazy(() => import("./components/Portal/Courses/EditCourses"));
const CreateCourse = lazy(() => import("./components/Portal/Courses/CreateCourse"));

const Users = lazy(() => import("./components/Portal/Users/Users"));
const CreateUser = lazy(() => import("./components/Portal/Users/CreateUser"));
const EditUser = lazy(() => import("./components/Portal/Users/EditUser"));

const LookAndFeel = lazy(() => import("./components/Portal/Settings/LookAndFeel"));
const EvaluationOptions = lazy(() => import("./components/Portal/Settings/EvaluationOptions"));
const Automation = lazy(() => import("./components/Portal/Automation/Automation"));
const CreateAutomation = lazy(() => import("./components/Portal/Automation/CreateAutomation"));
const EditAutomation = lazy(() => import("./components/Portal/Automation/EditAutomation"));
const Categories = lazy(() => import("./components/Portal/Categories/Categories"));
const EditCategories = lazy(() => import("./components/Portal/Categories/EditCategories"));
const CreateCategory = lazy(() => import("./components/Portal/Categories/CreateCategory"));

const SSO = lazy(() => import("./components/Portal/SSO/SSO"));
const EditSSO = lazy(() => import("./components/Portal/SSO/EditSSO"));
const CreateSSO = lazy(() => import("./components/Portal/SSO/CreateSSO"));

const Texts = lazy(() => import("./components/Portal/Settings/Texts"));
const Questions = lazy(() => import("./components/Portal/Questions/Questions"));
const EditQuestions = lazy(() => import("./components/Portal/Questions/EditQuestions"));
const CreateQuestions = lazy(() => import("./components/Portal/Questions/CreateQuestions"));

const Surveys = lazy(() => import("./components/Portal/Surveys/Surveys"));
const EditSurveys = lazy(() => import("./components/Portal/Surveys/EditSurveys"));
const CreateSurvey = lazy(() => import("./components/Portal/Surveys/CreateSurvey"));
const Activities = lazy(() => import("./components/Portal/Settings/Activities"));

const App = () => {
  document.addEventListener("click", function (event) {
    const expirationDate = new Date(Date.now() + 86400 * 1000); // Set the expiration date to 1 day from the current time
    const jwt = getCookie("jwt");
    setCookie("jwt", jwt, expirationDate);
  });

  return (
    <BrowserRouter>
      <Suspense fallback={<LoginFallback />}>
        <Routes>
          <Route path="*" element={<Launch />} />
          <Route path="/" element={<Launch />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/pending" element={<Pending />} />
          <Route path="/newuser" element={<CreateNewUser />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
          <Route path="/passwordresetconfirm" element={<PasswordResetConfirm />} />
          <Route path="/survey" element={<Survey />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/nopermission" element={<NoPermission />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/boards" element={<Boards />} />
            <Route path="/boards/create" element={<CreateBoard />} />
            <Route path="/boards/edit/:boardId" element={<EditBoard />} />
            <Route path="/evaluation/:target/:view" element={<Evaluation />} />
            <Route path="/evaluation/:target/:view/:Id" element={<Evaluation />} />
            <Route path="/profile" element={<Profile />} />
            <Route element={<AdminRoutes />}>
              <Route path="/link" element={<FeedbackLink />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/edit/:courseId" element={<EditCourses />} />
              <Route path="/courses/create" element={<CreateCourse />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/create" element={<CreateUser />} />
              <Route path="/users/edit/:userId" element={<EditUser />} />
              <Route path="/lookandfeel" element={<LookAndFeel />} />
              <Route path="/evaluationoptions" element={<EvaluationOptions />} />
              <Route path="/automation" element={<Automation />} />
              <Route path="/automation/create" element={<CreateAutomation />} />
              <Route path="/automation/edit/:automationId" element={<EditAutomation />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/categories/edit/:categoryId" element={<EditCategories />} />
              <Route path="/categories/create" element={<CreateCategory />} />
              <Route path="/sso" element={<SSO />} />
              <Route path="/sso/edit/:ssoId" element={<EditSSO />} />
              <Route path="/sso/create" element={<CreateSSO />} />
              <Route path="/texts" element={<Texts />} />
              <Route path="/questions" element={<Questions />} />
              <Route path="/questions/edit/:questionId" element={<EditQuestions />} />
              <Route path="/questions/create" element={<CreateQuestions />} />
              <Route path="/surveys" element={<Surveys />} />
              <Route path="/surveys/edit/:surveyId" element={<EditSurveys />} />
              <Route path="/surveys/create" element={<CreateSurvey />} />
              <Route path="/activitiesxy" element={<Activities />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
