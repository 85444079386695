export function berechneDurchschnittBewertung(data) {
  let sum = 0;
  let count = 0;

  data.forEach((item) => {
    if (item.Typ === "Numerische Eingabe") {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        sum += bewertung;
        count++;
      }
    }
  });

  // Schutz vor Division durch Null
  if (count === 0) {
    return 0;
  }
  const durchschnitt = sum / count;
  // Runden auf eine Dezimalstelle
  const gerundeterDurchschnitt = durchschnitt.toFixed(1);

  return parseFloat(gerundeterDurchschnitt); // Als Zahl zurückgeben
}

export function berechneNPS(data) {
  let sum = 0;
  let count = 0;
  let sumOfDetractors = 0;
  let sumOfPassives = 0;
  let sumOfPromotors = 0;

  data.forEach((item) => {
    if (item.Typ === "Net Promoter Score") {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        sum += bewertung;
        if (bewertung <= 6) {
          sumOfDetractors++;
        }
        else if (bewertung > 6 && bewertung <= 8){
          sumOfPassives++;
        }
        else{
          sumOfPromotors++;
        }
        count++;
      }
    }
  });

  // Schutz vor Division durch Null
  if (count === 0) {
    return 0;
  }
  const percentagePromotors = (sumOfPromotors/count)*100;
  const percentageDetractors = (sumOfDetractors/count)*100;
  const NPS = percentagePromotors - percentageDetractors;
  const durchschnitt = sum / count;
  // Runden auf eine Dezimalstelle
  const gerundeterDurchschnitt = durchschnitt.toFixed(1);
  const roundedNPS = NPS.toFixed(1);

  return parseFloat(roundedNPS); // Als Zahl zurückgeben
}

export function createSeriesForEvaluationPieChart(data) {
  let evaluationSeries = [0, 0, 0, 0, 0];
  data.forEach((item) => {
    if (item.Typ === "Numerische Eingabe") {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        evaluationSeries[bewertung - 1] += 1;
      }
    }
  });
  return evaluationSeries.reverse();
}

export function createObjectForTrainerPieChart(data) {
  const feedbackIDs = {};
  const feedbackSeries = {};

  data.forEach((feedback) => {
    const trainerID = feedback.Trainer;
    const trainerName = feedback.TrainerName;
    const feedbackID = feedback.CoursefeedbackID;

    if (!(feedbackID in feedbackIDs)) {
      if (trainerID in feedbackIDs) {
        feedbackSeries[trainerName]++;
      } else {
        feedbackIDs[trainerID] = true;
        feedbackSeries[trainerName] = 1;
      }
      feedbackIDs[feedbackID] = true;
    }
  });
  return feedbackSeries;
}

export function createObjectForCoursePieChart(data) {
  const feedbackIDs = {};
  const feedbackSeries = {};

  data.forEach((feedback) => {
    const courseID = feedback.Course;
    const courseName = feedback.CourseName;
    const feedbackID = feedback.CoursefeedbackID;

    if (!(feedbackID in feedbackIDs)) {
      if (courseID in feedbackIDs) {
        feedbackSeries[courseName]++;
      } else {
        feedbackIDs[courseID] = true;
        feedbackSeries[courseName] = 1;
      }
      feedbackIDs[feedbackID] = true;
    }
  });
  return feedbackSeries;
}

export function combineAndAverageFeedback(feedbackArray) {
  const combinedFeedback = {};

  feedbackArray.forEach((feedback) => {
    if (
      (feedback.Typ === "Bewertung" || feedback.Typ === "Numerische Eingabe") &&
      !isNaN(feedback.Value)
    ) {
      const feedbackID = feedback.CoursefeedbackID;
      const date = new Date(feedback.Date);
      // Create a combined key of feedbackID and date
      const combinedKey = `${date.getYear()}-${date.getMonth()}`; //Hier wird die Granularität der Auswertung definiert

      if (!combinedFeedback[combinedKey]) {
        // If the combinedFeedback object doesn't have an entry for this combined key, create one
        combinedFeedback[combinedKey] = {
          Typ: feedback.Typ,
          Date: date,
          CoursefeedbackID: feedbackID,
          Value: parseFloat(feedback.Value), // Assuming Value is a numeric property
          Count: 1,
        };
      } else {
        // If the entry already exists, update the average and count
        combinedFeedback[combinedKey].Value += parseFloat(feedback.Value);
        combinedFeedback[combinedKey].Count++;
      }
    }
  });

  // Calculate the average for each entry
  for (const combinedKey in combinedFeedback) {
    combinedFeedback[combinedKey].Value /= combinedFeedback[combinedKey].Count;
  }

  // Convert the combinedFeedback object back to an array
  const resultArray = Object.values(combinedFeedback);

  // Custom sorting export function for datetime
  function compareDatetime(a, b) {
    const dateA = new Date(a.Date);
    const dateB = new Date(b.Date);
    return dateA - dateB;
  }

  // Sort the array using the custom export function
  resultArray.sort(compareDatetime);
  return resultArray;
}

export function combineAndAverageNPS(feedbackArray) {
  const combinedFeedback = {};
  
  let count = 0;

  let sumOfDetractors = 0;
  let sumOfPassives = 0;
  let sumOfPromotors = 0;

  feedbackArray.forEach((feedback) => {
    if (
      (feedback.Typ === "Bewertung" || feedback.Typ === "Net Promoter Score") &&
      !isNaN(feedback.Value)
    ) {
      const feedbackID = feedback.CoursefeedbackID;
      const date = new Date(feedback.Date);
      // Create a combined key of feedbackID and date
      const combinedKey = `${date.getYear()}-${date.getMonth()}`; //Hier wird die Granularität der Auswertung definiert
      if (!combinedFeedback[combinedKey]) {
        count = 0;

        sumOfDetractors = 0;
        sumOfPassives = 0;
        sumOfPromotors = 0;
      }
      const bewertung = parseFloat(feedback.Value);
      if (!isNaN(bewertung)) {
        if (bewertung <= 6) {
          sumOfDetractors++;
        }
        else if (bewertung > 6 && bewertung <= 8){
          sumOfPassives++;
        }
        else{
          sumOfPromotors++;
        }
        count++;
      }
      const percentagePromotors = (sumOfPromotors/count)*100;
      const percentageDetractors = (sumOfDetractors/count)*100;
      const NPS = percentagePromotors - percentageDetractors;
      // Runden auf eine Dezimalstelle
      const roundedNPS = NPS.toFixed(1);

      if (!combinedFeedback[combinedKey]) {
        // If the combinedFeedback object doesn't have an entry for this combined key, create one
        combinedFeedback[combinedKey] = {
          Typ: feedback.Typ,
          Date: date,
          CoursefeedbackID: feedbackID,
          Value: parseFloat(roundedNPS), // Assuming Value is a numeric property
          Count: 1,
        };
      } else {
        // If the entry already exists, update the average and count
        combinedFeedback[combinedKey].Value = parseFloat(roundedNPS);
        combinedFeedback[combinedKey].Count++;
      }
    }
  });

  // Convert the combinedFeedback object back to an array
  const resultArray = Object.values(combinedFeedback);

  // Custom sorting export function for datetime
  function compareDatetime(a, b) {
    const dateA = new Date(a.Date);
    const dateB = new Date(b.Date);
    return dateA - dateB;
  }

  // Sort the array using the custom export function
  resultArray.sort(compareDatetime);
  return resultArray;
}

export function createObjectForTimelineChart(data) {
  data = combineAndAverageFeedback(data);
  const feedbackData = [];

  data.forEach((feedback) => {
    if (feedback.Typ === "Numerische Eingabe" && !isNaN(feedback.Value)) {
      const myDate = new Date(feedback.Date);

      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1; // Adding 1 to get a one-based month

      // Format the result as 'YYYY-MM'
      const yearMonthString = `${year}-${month.toString().padStart(2, "0")}`;

      // Create a new Date object from the formatted string
      const yearMonthDate = new Date(yearMonthString);
      const newFeedback = {
        x: yearMonthDate,
        y: parseFloat(feedback.Value).toFixed(1),
      };
      feedbackData.push(newFeedback);
    }
  });

  return feedbackData;
}

export function createObjectForNPSTimelineChart(data) {
  data = combineAndAverageNPS(data);
  const feedbackData = [];

  data.forEach((feedback) => {
    if (feedback.Typ === "Net Promoter Score" && !isNaN(feedback.Value)) {
      const myDate = new Date(feedback.Date);

      const year = myDate.getFullYear();
      const month = myDate.getMonth() + 1; // Adding 1 to get a one-based month

      // Format the result as 'YYYY-MM'
      const yearMonthString = `${year}-${month.toString().padStart(2, "0")}`;

      // Create a new Date object from the formatted string
      const yearMonthDate = new Date(yearMonthString);
      const newFeedback = {
        x: yearMonthDate,
        y: parseFloat(feedback.Value).toFixed(1),
      };
      feedbackData.push(newFeedback);
    }
  });

  return feedbackData;
}

export function berechneTrend(data) {
  let sum = 0;
  let count = 0;
  let i = 0;
  let Durchschnitt = berechneDurchschnittBewertung(data);

  data.forEach((item) => {
    if (item.Typ === "Numerische Eingabe" && i < 10) {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        sum += bewertung;
        count++;
      }
      i++;
    }
  });

  // Schutz vor Division durch Null
  if (count === 0) {
    return 0;
  }
  const durchschnittOfLast10 = sum / count;
  // Runden auf eine Dezimalstelle
  const Trend = durchschnittOfLast10.toFixed(1) - Durchschnitt;

  return parseFloat(Trend).toFixed(1); // Als Zahl zurückgeben
}

export function berechneNPSTrend(data) {
  let count = 0;
  let NPS = berechneNPS(data);

  let sumOfDetractors = 0;
  let sumOfPassives = 0;
  let sumOfPromotors = 0;

  data.forEach((item) => {
    if (item.Typ === "Net Promoter Score" && count < 10) {
      const bewertung = parseFloat(item.Value);
      if (!isNaN(bewertung)) {
        if (bewertung <= 6) {
          sumOfDetractors++;
        }
        else if (bewertung > 6 && bewertung <= 8){
          sumOfPassives++;
        }
        else{
          sumOfPromotors++;
        }
        count++;
      }
    }
  });


  const percentagePromotors = (sumOfPromotors/count)*100;
  const percentageDetractors = (sumOfDetractors/count)*100;
  const NPSLast10 = percentagePromotors - percentageDetractors;
  // Runden auf eine Dezimalstelle
  const roundedNPSLast10 = NPSLast10.toFixed(1);
  // Runden auf eine Dezimalstelle
  const Trend = roundedNPSLast10 - NPS;

  return parseFloat(Trend).toFixed(1); // Als Zahl zurückgeben
}

export function createSeriesForTrainerPieChart(inputObject) {
  const namesArray = [];
  const numbersArray = [];

  for (const [name, number] of Object.entries(inputObject)) {
    namesArray.push(name);
    numbersArray.push(number);
  }

  return { labels: namesArray, series: numbersArray };
}

export function createSeriesForCoursePieChart(inputObject) {
  const namesArray = [];
  const numbersArray = [];

  for (const [name, number] of Object.entries(inputObject)) {
    namesArray.push(name);
    numbersArray.push(number);
  }

  return { labels: namesArray, series: numbersArray };
}

export function calculateDaysSinceLastFeedback(data) {
  let latestDate = new Date(data[0].Date);
  let today = new Date();

  const timeDifferenceInMilliseconds = new Date() - latestDate;

  const timeDifferenceInDays = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  let textToShow = `vor ${timeDifferenceInDays} Tagen`;
  if (timeDifferenceInDays === 0 || timeDifferenceInDays === 1) {
    if (today.getDate() != latestDate.getDate()) {
      textToShow = "gestern";
    } else textToShow = "heute";
  }

  return textToShow;
}

export function Next(diagramID) {
  const nextButton = document.querySelector(
    diagramID + ">.carousel-control-next"
  );
  if (nextButton) {
    nextButton.click();
  }
}

export function Prev(diagramID) {
  const nextButton = document.querySelector(
    diagramID + ">.carousel-control-prev"
  );
  if (nextButton) {
    nextButton.click();
  }
}
