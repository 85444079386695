import React from "react";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import emptyImage from "../Assets/empty.png";
import Alert from "@mui/material/Alert";

export default function Empty() {
  return (
    <Fade in={true}>
      <Paper
        className="paper"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Alert severity="info">
          Kein Feedback gefunden - Filter ändern um mehr Feedback anzuzeigen
        </Alert>
        <img className="emptyImage" src={emptyImage}></img>
      </Paper>
    </Fade>
  );
}
