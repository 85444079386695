import titleimage from "../../Assets/graduation-hat.png"
import optionsimage from "../../Assets/course-options.png"
import { introConfig } from "./introConfig";

export function courseIntro() {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: "Kursliste <img src="+titleimage+" width=35 height=35/>",
        intro: 'Verwalte die Kurse deines Systems indem du neue Kurse hinzufügst, bestehende Kursdaten bearbeitest oder bestehende Kurse löscht',
      },
      {
        title: "Kurstabelle",
        element: document.querySelector(".MuiDataGrid-root"),
        intro: "In einer Tabelle werden alle gespeicherten Kurse gelistet. Über das Suchfeld wird auf bestimmte Benutzer eingeschränkt",
      },     
      {
        title: "Menu 🚩",
        element: document.querySelector("#menu-button"),
        intro: "Klicke hier um zusätzliche Optionen zum gewählten Kurs zu erhalten.<br/><br><img src="+optionsimage+" width=190 height=140/>",
      },
    ],
  };
}
