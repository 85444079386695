import React, { useContext, useEffect } from "react";
import { UserContext } from "../Users/UserContext";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { transparentize } from "polished";
import * as Icons from "@mui/icons-material";
import { FaCircle } from "react-icons/fa6";
import { ActiveNavigationContext } from "./Sidebar";
import getSidebarConfig from "./sidebarConfig";
import Help from "./Help";

export default function SidebarMenu(props) {
  const { currentUserData } = useContext(UserContext);
  const [openFeedback, setOpenFeedback] = React.useState(false);  
  const {activeNavigation} = useContext(ActiveNavigationContext)
  let selectedPage = activeNavigation.activePage;
  let selectedSubPage = activeNavigation.activeSubPage;
  const navigate = useNavigate();
  let sidebarConfig = getSidebarConfig(props.module)

  var theme = useTheme();

  const themeSidebarOpen = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "rgb(99, 115, 129)",
            "&.Mui-selected": {
              color: theme.palette.primary.main + "!important",
              backgroundColor:
                transparentize(0.85, theme.palette.primary.main) + "!important",
            },
          },
        },
      },
    },
  });

  const handleSubMenuClick = (event, route) => {
    props.setOpen(false);
    navigate(route);
  };

  const handleMenuClick = (menuType) => {
    if (openFeedback === menuType) {
      setOpenFeedback("");
    } else {
      setOpenFeedback(menuType);
    }
  };

  const generateMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
      const { id, icon, text, route, subMenu } = menuItem;
      const IconComponent = Icons[icon];

      if (subMenu) {
        return (
          <div key={id}>
            <MenuItem
              id={id}
              selected={selectedPage === id}
              onClick={(event) => handleMenuClick(id)}
            >
              <ListItemIcon>{IconComponent && <IconComponent />}</ListItemIcon>
              <ListItemText primary={text} />
              {openFeedback === id ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>

            <Collapse in={openFeedback === id} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {subMenu.map((subMenuItem) => (
                  <MenuItem
                    key={subMenuItem.id}
                    sx={{
                      color:
                        selectedSubPage === subMenuItem.id
                          ? theme.palette.primary.main + "!important"
                          : "",
                    }}
                    onClick={(event) =>
                      handleSubMenuClick(
                        event,
                        subMenuItem.route,
                      )
                    }
                  >
                    <ListItemIcon style={{ marginLeft: "15px" }}>
                      <FaCircle size={5} />
                    </ListItemIcon>
                    <ListItemText primary={subMenuItem.text} />
                    {selectedSubPage === subMenuItem.id && <Help/>}
                  </MenuItem>
                ))}
              </List>
            </Collapse>
          </div>
        );
      } else {
        return (
          <MenuItem
            key={id}
            id={id}
            selected={selectedPage === id}
            onClick={(event) => handleSubMenuClick(event, route)}
          >
            <ListItemIcon>{IconComponent && <IconComponent />}</ListItemIcon>
            <ListItemText primary={text} />
            {selectedPage === id && <Help/>}
          </MenuItem>
        );
      }
    });
  };

  return (
    <ThemeProvider theme={themeSidebarOpen}>
      <MenuList
        component="nav"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {props.module === "Feedback" ? "Feedback Auswertung" : props.module}
          </ListSubheader>
        }
      >
        {sidebarConfig.main && generateMenuItems(sidebarConfig.main)}
      </MenuList>

      {currentUserData.role > 1 && sidebarConfig.settings.length>0 && (
        <MenuList
          component="nav"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {props.module} Administration
            </ListSubheader>
          }
        >
          {generateMenuItems(sidebarConfig.settings)}
        </MenuList>
      )}
    </ThemeProvider>
  );
}
