import {deleteCookie} from '../Common/deleteCookie';

export function logout() {
  localStorage.setItem("isLoggedIn", false);
  localStorage.removeItem("loggedInUser");
  deleteCookie('jwt');
    window.location.href = '/'
  }




