import { baseURL } from "../Common/baseURL.js";
import { setCookie } from "../Common/setCookie.js";

export async function validateSSOCredentials(userData) {
  const res = await fetch(baseURL + "ssoAuthenticate.php", {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: JSON.stringify({
      email: userData.email,
      idToken: userData.idToken,
    }),
  });
  if (res.status >= 200 && res.status <= 299) {
    const jwt = await res.text();
    if (jwt === "user not registered" || jwt === "invalid id token") {
      return jwt;
    } else {
      const expirationDate = new Date(Date.now() + 86400 * 1000); // Set the expiration date to 1 day from the current time
      setCookie("jwt", jwt, expirationDate);
      return true;
    }
  } else {
    return false;
  }
}
