import titleimage from "../../Assets/survey.png"
import { introConfig } from "./introConfig";

export function questionsIntro() {
  const config = introConfig(); // Get the configuration object

  return {
    ...config,
    steps: [
      {
        title: "Fragen  <img src="+titleimage+" width=35 height=35/>",
        intro: "Verwalte die Fragen in deinem System, indem du neue Fragen hinzufügst, vorhandene Fragen bearbeitest oder löscht.",
      },
      {
        title: "Fragentabelle",
        element: document.querySelector(".MuiDataGrid-root"),
        intro: "In einer Tabelle werden alle gespeicherten Fragen gelistet. Über das Suchfeld wird auf bestimmte Fragen eingeschränkt. <br/> <br/>Jeder Frage kann eine Kategorie zugewiesen werden. Feedback zu Fragen einer Kategorie kann über das Dashboard gezielt ausgewertet werden",
      },     
      {
        title: "Menu 🚩",
        element: document.querySelector("#menu-button"),
        intro: "Klicke hier um Fragen zu bearbeiten bzw zu löschen.",
      },
    ],
  };
}
