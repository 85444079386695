import React from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./users.css";
import "./Login.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { createUser } from "./loginFunctions";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Spinner from "../../Common/Spinner";
import CelebrateGif from "../../Assets/giphy.gif";
import failedImg from "../../Assets/failed.svg";
import { deleteCookie } from "../../Common/deleteCookie";

export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const [userFetched, setUserFetched] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!userFetched) {
      createUser(code, setUserFetched, setSuccess);
    }
  }, []);

  return (
    <>
      <div className="loginBackground"></div>
      {userFetched ? (
        <div className="loginframe">
          <Fade in={true}>
            <Paper
              className="paper"
              style={{
                width: "50%",
                padding: "30px",
              }}
            >
              {success ? (
                <Stack
                  direction={{ xs: "column-reverse", md: "row" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={3}
                >
                  <img
                    src={CelebrateGif}
                    alt="celebrationgif"
                    style={{ height: "250px", width: "250px" }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <header>Erfolgreiche Benutzerregistrierung</header>
                    <p>
                      Dein Benutzer wurde erfolgreich registriert. Du kannst
                      jetzt zum Login zurückkehren und dich mit deinen gewählten
                      Zugangsdaten anmelden
                    </p>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        window.location.href = "/login";
                        navigate("/login");
                        deleteCookie("jwt");
                        localStorage.removeItem("isLoggedIn");
                        localStorage.removeItem("loggedInUser");
                      }}
                    >
                      zum Login
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Stack
                  direction={{ xs: "column-reverse", md: "row" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={3}
                >
                  <img
                    src={failedImg}
                    alt="failed"
                    style={{ height: "250px", width: "250px" }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <header>Fehlgeschlagene Benutzerregistrierung</header>
                    <p>
                      Beim Erstellen deines Benutzers ist ein Fehler
                      aufgetreten. Möglicherweise hast du dich bereits
                      registriert oder einen abgelaufenen Verifikationslink
                      verwendet. Bitte versuche es erneut oder kontaktiere den
                      Support.
                    </p>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        window.location.href = "/login";
                        navigate("/login");
                        deleteCookie("jwt");
                        localStorage.removeItem("isLoggedIn");
                        localStorage.removeItem("loggedInUser");
                      }}
                    >
                      zum Login
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Paper>
          </Fade>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
