import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { updateState } from "../../Questions/questionsFunctions";
import { sendGetRequest } from "../../../Common/sendGetRequest";

const CategorySelect = (props) => {
  const emptyObject = {
    id: "",
    label: "",
  }
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(emptyObject);

  useEffect(() => {
    sendGetRequest("getCategories.php").then((response) => {
      setOptions(response);
    });
  }, []);

  useEffect(() => {
      let currentCategoryChoice = options.filter(option => option.id == props.categoryId )
      if(currentCategoryChoice.length>0)
      {
        setValue({
          id: currentCategoryChoice[0].id,
          label: currentCategoryChoice[0].Category,
        })
      }
    }, [props.categoryId, options]);

  let menuItems = options.map((option) => ({
    id: option.id,
    label: option.Category,
  }));

  return (
      <Autocomplete
      id="categorySelect"
      style={{ width: "100%" }}
      options={menuItems}
      value={value}
      inputValue={value.label}
      onChange={(event, newValue) => {
        props.setCategoryId(newValue ? newValue.id : "");
        setValue(newValue ? newValue : emptyObject)
      }}   
      onInputChange={(event, newValue) => {
        updateState("label", newValue, setValue)
      }}   
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Fragenkategorie"
          variant="standard"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default CategorySelect;
