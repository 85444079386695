import React, { useEffect, useState } from "react";
import { sendPostRequest } from "../../../Common/sendPostRequest";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export default function AIReport({ comments, report, setReport }) {
  const [loading, setLoading] = useState(false);
  function generateReport() {
    setLoading(true);
    setReport("");
    sendPostRequest("postAIReport.php", { comments: comments }).then(
      (response) => {
        setReport(JSON.parse(response).choices[0].message.content);
        setLoading(false);
      }
    );
  }
  return (
    <>
      {report === "" ? (
        <div
          style={{
            width: "100%",
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading === true ? (
            <Fade in={true}>
              <CircularProgress size={70} />
            </Fade>
          ) : (
            <Button variant="contained" onClick={generateReport}>
              <AutoAwesomeIcon style={{marginRight:"10px"}} /> KI-Report erstellen
            </Button>
          )}
        </div>
      ) : (
        <>
          {report}
          <Button
            variant="contained"
            style={{ width: "270px", marginTop:"30px" }}
            onClick={generateReport}
          >
            <AutoAwesomeIcon style={{marginRight:"10px"}}/> KI-Report aktualisieren
          </Button>
        </>
      )}
    </>
  );
}
