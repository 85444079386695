import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FaFilter } from "react-icons/fa6";
import TrainerSelect from "../../Courses/TrainerSelect";
import CourseSelect from "../../Users/CourseSelect";
import DateRange from "../DateRange";
import React, { useContext, useEffect } from "react";
import { updateState } from "../../Surveys/surveysFunctions";
import QuestionSelect from "../../Questions/QuestionSelect";
import { useLocation, useNavigate } from "react-router-dom";
import CategorySelect from "./CategorySelect";
import CourseSelectWithoutKeine from "../../Users/CourseSelectWithoutKeine";
import SurveySelect from "../../Surveys/SurveySelect";
import Stack from "@mui/material/Stack";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import { UserContext } from "../../Users/UserContext";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 320,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Filter(props) {
  const location = useLocation();
  const { currentUserData } = useContext(UserContext);
  var currentUrl = new URL(window.location.href);
  const queryParams = new URLSearchParams(location.search);
  const queryTrainer = queryParams.get("trainer");
  const querySurvey = queryParams.get("survey");
  const queryCategory = queryParams.get("category");
  const queryCourse = queryParams.get("course");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [questionId, setQuestionId] = React.useState(
    props.filter.hasOwnProperty("question") ? props.filter.question : false
  );
  const [trainerId, setTrainerId] = React.useState(
    props.filter.hasOwnProperty("trainer") ? props.filter.trainer : false
  );
  const [category, setCategory] = React.useState(
    props.filter.hasOwnProperty("category") ? props.filter.category : false
  );
  const [surveyId, setSurveyId] = React.useState(props.filter.survey);
  const [courseId, setCourseId] = React.useState(props.filter.course);
  const [startDate, setStartDate] = React.useState(props.filter.startDate);
  const [endDate, setEndDate] = React.useState(props.filter.endDate);
  const navigate = useNavigate();

  useEffect(() => {
    changeQueryParams();
    questionId !== false && setQuestionId(props.filter.question);
    category !== false && setCategory(props.filter.category);
    trainerId !== false && setTrainerId(props.filter.trainer);
    setSurveyId(props.filter.survey);
    setCourseId(props.filter.course);
    setStartDate(props.filter.startDate);
  }, [props.filter]);

  useEffect(() => {
    if (currentUserData.role > 1 && queryTrainer !== null && props.filter.hasOwnProperty("trainer")) {
      setTrainerId(queryTrainer);
      updateState("trainer", queryTrainer, props.setFilter);
    }
    if (queryCategory !== null && props.filter.hasOwnProperty("category")) {
      setCategory(queryCategory);
      updateState("category", queryCategory, props.setFilter);
    }
    if (querySurvey !== null) {
      setSurveyId(querySurvey);
      updateState("survey", querySurvey, props.setFilter);
    }
    if (queryCourse !== null) {
      setCourseId(queryCourse);
      updateState("course", queryCourse, props.setFilter);
    }
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (props.filter.hasOwnProperty("question")) {
      updateState("question", questionId, props.setFilter);
    }
    trainerId !== false && updateState("trainer", trainerId, props.setFilter);
    category !== false && updateState("category", category, props.setFilter);
    updateState("survey", surveyId, props.setFilter);
    updateState("course", courseId, props.setFilter);
    updateState("startDate", startDate, props.setFilter);
    updateState("endDate", endDate, props.setFilter);
    props.setActiveIndex(0);
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" flexWrap="wrap" useFlexGap spacing={{ xs: 1, sm: 2 }}>
      <Button
        id="filter-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<FaFilter size="15px" />}
      >
        Filter
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {trainerId !== false && (
          <MenuItem disableRipple>
            <TrainerSelect
              trainerId={trainerId}
              setTrainerId={setTrainerId}
              width={"100%"}
            />
          </MenuItem>
        )}
        <MenuItem disableRipple>
          {props.target === "Kursteilnehmer" ? (
            <CourseSelectWithoutKeine
              courseId={courseId}
              setCourseId={setCourseId}
              width={"100%"}
            />
          ) : (
            <CourseSelect
              courseId={courseId}
              setCourseId={setCourseId}
              width={"100%"}
            />
          )}
        </MenuItem>
        {category !== false && (
          <MenuItem disableRipple>
            <CategorySelect
              categoryId={category}
              setCategoryId={setCategory}
              width={"100%"}
            />
          </MenuItem>
        )}
        <MenuItem disableRipple>
          <SurveySelect
            setSurveyId={setSurveyId}
            surveyId={surveyId}
            width={"100%"}
            target={props.target}
          />
        </MenuItem>
        <MenuItem>
          <Button variant="contained" onClick={handleApply}>
            Anwenden
          </Button>
        </MenuItem>
      </StyledMenu>
      <Stack color="primary" direction="row" alignItems="center" sx={{ color: "primary.main", border: "0.8px solid", borderRadius: "16px", backgroundColor: "white", padding: "5px" }} spacing={1}>
        <CalendarMonthTwoToneIcon color="primary" />
        <DateRange
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          handleApply={handleApply}
          color={"blue"}
        />
      </Stack>
    </Stack>
  );

  function changeQueryParams() {
    let newPath = "";
    if (props.filter.hasOwnProperty("question")) {
      if (props.filter.question !== "") {
        if (props.target === "Kursteilnehmer") {
          newPath = "/evaluation/Kursteilnehmer/questions/" + props.filter.question;
        } else {
          newPath = "/evaluation/Intern/questions/" + props.filter.question;
        }
      } else {
        if (props.target === "Kursteilnehmer") {
          newPath = "/evaluation/Kursteilnehmer/questions";
        } else {
          newPath = "/evaluation/Intern/questions";
        }
      }
      navigate(newPath)
      currentUrl.pathname = newPath;
    }
    props.filter.hasOwnProperty("trainer") && props.filter.trainer !== ""
      ? queryParams.set("trainer", trainerId)
      : queryParams.delete("trainer");
    props.filter.hasOwnProperty("category") && props.filter.category !== ""
      ? queryParams.set("category", category)
      : queryParams.delete("category");
    props.filter.course !== ""
      ? queryParams.set("course", courseId)
      : queryParams.delete("course");
    props.filter.survey !== ""
      ? queryParams.set("survey", surveyId)
      : queryParams.delete("survey");
    currentUrl.search = queryParams.toString();
    window.history.replaceState({}, "", currentUrl.href);
  }
}
