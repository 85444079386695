import { Navigate, Outlet, useLocation } from "react-router-dom";
import Page from "./Page";
import CommonSidebar from "../Portal/Sidebar/Sidebar";
import { Suspense } from "react";
import { getUserRoleFromJWT } from "../Authentication/getUserRoleFromJWT";
import { UserProvider } from "../Portal/Users/UserContext";
import Spinner from "./Spinner";
import { useSSOLogin } from "../Authentication/useSSOLogin";

const PrivateRoutes = () => {
  useSSOLogin();
  var location = useLocation();
  let Role = getUserRoleFromJWT();
  return Role !== 0 ? (
    <UserProvider>
      <Suspense
        fallback={
          <>
            <CommonSidebar />
            <Spinner />
          </>
        }
      >
        <Page>
          <Outlet />
        </Page>
      </Suspense>
    </UserProvider>
  ) : (
    <Navigate to="/pending" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
